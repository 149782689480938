<template>
  <v-dialog
    content-class="cross__dialog"
    transition="dialog-bottom-transition"
    v-model="visibility"
    max-width="650px"
  >
    <v-card>
      <div class="cross__inner">
        <v-btn
          fab
          small
          color="white"
          elevation="0"
          @click="$emit('close_modal')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="pt-5">
        <v-list dense>
          <v-list-item-group color="success">
            <v-subheader>{{ $t("crm.itemProduct") }}</v-subheader>
            <v-list-item style="cursor: default !important" color="success">
              <v-list-item-title>
                <div>{{ product.name }}</div>
              </v-list-item-title>
              <v-list-item-action class="w-100 pointer" @click="copyToClipboard(product.uuid)">
                <v-row justify="end">
                  <div>
                    <span class="text-decoration-underline letter-spacing">{{ product.uuid }}</span>
                    <v-icon class="ml-1" small>mdi-content-copy</v-icon>
                  </div>
                </v-row>
              </v-list-item-action>
            </v-list-item>

            <v-subheader v-if="product.options_details && product.options_details.length">{{ $t("products.options") }}</v-subheader>
            <v-list-item style="cursor: default !important"
              v-for="(option, index) in product.options_details"
              :key="index"
            >
              <v-list-item-title>
                <div>{{ option.name }}</div>
              </v-list-item-title>
              <v-list-item-action @click="copyToClipboard(option.uuid)" class="w-100 pointer">
                <v-row justify="end">
                  <div>
                    <span class="text-decoration-underline letter-spacing">{{ option.uuid }}</span>
                    <v-icon class="ml-1" small>mdi-content-copy</v-icon>
                  </div>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import notifications from "@/mixins/notifications";

export default {
  mixins: [notifications],
  props: {
    visible: {
      require: true,
    },
    product: {
      require: true,
    },
  },
  data: () => ({
    product: null,
  }),
  methods: {
    async copyToClipboard(uuid) {
      try {
        await navigator.clipboard.writeText(uuid);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close_modal");
      },
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.letter-spacing {
    letter-spacing: 0.4px;
}
</style>

