export default {
  data: () => ({
    page: 1,
    perPageList: [5, 10, 15],
    firstNumber: 1,
    secondNumber: 1,
    total: 1,
  }),
  methods: {
    reorderNumbers() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
    },
    async prevPage() {
      if (this.page > 1) {
        this.page -= 1;
        await this.emitPageChange();
      }
    },
    async nextPage() {
      this.page += 1;
      await this.emitPageChange();
    },
  },
  computed:{
    rowHeight(){
      console.log(this.isLarge)
      if(!this.isLarge){
        return 35
      }else{
        return 48
      }
    },
    perPage(){
        return Math.ceil((window.innerHeight - 120) / this.rowHeight);
    },
    largeLimit(){
      let pageLimit = this.perPage * (this.page)
      if (pageLimit == 0) {
        pageLimit = this.perPage
      }
      if(pageLimit >= 1000){
          this.page = (this.pageBeforeLimit - 1)
          this.$scrollTop()
          return 1000
      }
      return pageLimit
    }
  },
  watch: {
    page(e){
      if(this.perPage * (this.page) >= 1000 && this.perPage * (this.page) <=  1000 + this.perPage) {
        this.pageBeforeLimit = e
        console.log(e)
      }
    },
    async perPage() {
      await this.emitPageChange();
    },
  },
};
