<template>
  <div>
    <VBtn class="mb-3" depressed plain @click="onBack">
      <VIcon left>mdi-chevron-left</VIcon>
      {{ $t("btn.back") }}
    </VBtn>
    <VRow>
      <VCol cols="12" md="4" sm="12">
        <VCard>
          <VCardText>
            <img
              v-if="mainItem.path_img_thumb"
              :src="mainItem.path_img_thumb"
              alt=""
              class="w-100 br-10 w-100 object-fit-contain"
              height="200"
              width="250"
            />
            <img
              v-else
              :src="require('../../assets/images/bar-code-page.png')"
              alt=""
              class="w-100 mb-0 pb-0 h-100 object-fit-contain"
            />
            <div class="px-5">
              <VRow class="pa-0 ma-2 justify-space-between">
                <span
                  class="d-block font-16 text-uppercase text-left mt-4 font-weight-bold"
                  style="color: #676767"
                >
                  {{ getItemDetailsProp("name") }}
                </span>
              </VRow>
              <!-- <VRow class="pa-0 ma-0">
                    <VCol class="pr-0 text-right" cols="12">
                  <VIcon>mdi-information-outline</VIcon>
                </VCol>

              </VRow> -->
            </div>
          </VCardText>
        </VCard>
        <VCard class="mt-2">
          <VCardText>
            <VRow>
              <VCol>
                <VBtn text color="primary">
                  <VIcon left>
                    mdi-information-outline
                  </VIcon>
                  {{ $t("products.info") }}
                </VBtn>
              </VCol>
              <VCol class="text-right">
                <VBtn @click="integrateDialog = true" icon color="gray">
                  <VIcon>
                    mdi-link
                  </VIcon>
                </VBtn>
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="auto" class="pr-0">
                <VIcon> mdi-move-resize-variant </VIcon>
              </VCol>
              <VCol>
                <span>{{ $t("products.dimensions") }} – </span>
                {{ mainItem.size_height }} x {{ mainItem.size_length }} x {{ mainItem.size_width }}
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="auto" class="pr-0">
                <VIcon>
                  mdi-cube-scan
                </VIcon>
              </VCol>
              <VCol>
                <span>{{ $t("products.capacity") }} – </span>({{ itemCapacity }})
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
      <VCol cols="12" md="8">
        <VCard>
          <VCardText>
            <chip-tab
              v-if="mainItem.options_details"
              :items="mainItem.options_details"
              @onClickChipTab="onClickChipTab"
              class="mb-2 ml-3"
              :showCount="true"
            />
            <v-simple-table class="my-table" :dense="!isLarge">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-bold ">
                      <div class="pb-1 d-flex align-center">
                        <VTextField
                          dense
                          :placeholder="$t('table.ns_code')"
                          v-model="searchNsCode"
                          clearable
                          :loader-height="5"
                          @input="onSearch({ ns_code: searchNsCode })"
                          hide-details
                          solo
                        />
                        <span
                          class="d-block font-14  pl-3 text-uppercase text-left font-weight-medium"
                          style="color: #676767"
                        >
                          ({{ mainItem.amount }})
                        </span>
                      </div>
                    </th>
                    <th class="text-left font-weight-bold">{{ $t("products.option") }}</th>
                    <th class="text-left font-weight-bold" v-if="permissions.not_client">
                      {{ $t("table.sector") }}
                    </th>
                    <th class="text-left font-weight-bold" v-if="permissions.not_client">
                      {{ $t("table.cell") }}
                    </th>
                    <th class="text-left font-weight-bold">{{ $t("table.date_of_receipt") }}</th>
                  </tr>
                </thead>

                <tbody name="fade">
                  <tr v-if="!items.length" aria-colspan="12">
                    <td colspan="3">
                      <h3 class="text-center">{{ $t("table.noData") }}</h3>
                    </td>
                  </tr>
                  <tr v-for="product in items" :key="product.uuid" v-else>
                    <td>{{ product.ns_code }}</td>
                    <td>{{ product.option_details.name || "--" }}</td>
                    <td v-if="permissions.not_client">
                      <span>{{ product.sector_details.name }}</span>
                    </td>
                    <td v-if="permissions.not_client">
                      <span v-if="getCellName(product.cell_details) !== 'undefined'">
                        {{ getCellName(product.cell_details) || "--" }}
                      </span>
                      <VIcon v-else :dense="!isLarge">
                        mdi-help-circle-outline
                      </VIcon>
                    </td>
                    <td>{{ product.time_created | date }}</td>
                    <!-- <td v-if="0" class="text-right">
                    <VIcon
                      class="cursor-pointer"
                      style="color:green"
                      @click="onSendItem(product)"
                    >mdi-send
                    </VIcon>
                  </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </VCardText>
        </VCard>
        <div class="text-center mt-3">
          <VBtn
            v-if="!loadMoreDisabled"
            class="br-10"
            color="blue"
            dark
            depressed
            outlined
            @click="nextPage"
          >
            {{ $t("loadMore") }}
          </VBtn>
        </div>
      </VCol>
    </VRow>
    <!--    <AddSupplyOutDialog-->
    <!--      :visible="addSupplyOutDialog"-->
    <!--      @close="addSupplyOutDialog = false; addSupplyItem = ''"-->
    <!--      :default-item="addSupplyItem"-->
    <!--      :states="[]"-->
    <!--    />-->

    <integration-dialog :visible="integrateDialog" v-if="integrateDialog" :product="mainItem" @close_modal="integrateDialog = false"/>
  </div>
</template>

<script>
import _ from "lodash";
import depotService from "../../services/request/depot/depotService";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import language from "../../mixins/language";
import EventBus from "../../events/EventBus";
import supplyCart from "../../mixins/supplyCart";
// import AddSupplyOutDialog from '../../components/supply/AddSupplyOutDialog.vue';
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import { uniqueByUUID } from "../../mixins/helpers";
import SizeUi from "../../mixins/SizeUi";
import chipTab from "@/components/common/chipTab.vue";
import IntegrationDialog from '@/components/products/integrationDialog.vue'

export default {
  name: "ProductsByBarCode",
  mixins: [loader, notifications, language, user, supplyCart, paginationMixin, SizeUi],
  components: {
    chipTab,
    IntegrationDialog
    // AddSupplyOutDialog,
  },
  data: () => ({
    mainItem: [],
    addSupplyOutDialog: false,
    addSupplyItem: "",
    viewProductDialog: false,
    viewProduct: null,
    items: [],
    createDialog: false,
    searchString: "",
    searchLoading: false,
    page: 1,
    perPageList: [5, 10, 15],
    total: 24,
    lastPage: 10,
    paginatedProducts: [],
    firstNumber: "",
    secondNumber: "",
    loadMoreDisabled: false,
    itemCapacity: "",
    options: [],
    isOptionsMenu: false,
    searchNsCode: "",
    integrateDialog: false,
  }),
  methods: {
    onClickChipTab(optionName) {
      if (optionName === this.$t("btn.all")) {
        return this.onSearch();
      }
      this.onSearch({ option_details__name: optionName });
    },
    async onSearch(params) {
      this.searchLoading = true;
      try {
        this.page = 1;
        const newItems = await depotService.getMyProducts({
          ...params,
          uuid_product: this.$route.params.barCode,
          id_state: 810,
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          uselike: true
        });
        this.items = [];
        this.items.push(...newItems);
        // console.log('this.items', this.items);
        // this.items = Object.values(this.items);
        this.items = uniqueByUUID(this.items);
        this.loadMoreDisabled = newItems.length < this.perPage;
      } catch (e) {
        this.searchLoading = false;
        this.setErrorNotification(e);
      }
      this.searchLoading = false;
    },
    getCapacity() {
      this.itemCapacity =
        (this.mainItem.size_height * this.mainItem.size_length * this.mainItem.size_width) /
        1000000000;
    },
    onBack() {
      // if (this.getUser.type === 'client') {
      //   this.$router.push({name: 'products'});
      // } else {
      this.$router.push({ name: "products" });
      // }
    },
    getItemDetailsProp(prop) {
      return _.get(this.mainItem, `${prop}`);
    },
    onSendItem(product) {
      this.addProductCart(product);
      this.openCart();
    },
    onChangePerPage() {
      this.page = 1;
    },
    previousPage() {
      if (this.page !== 1) this.page -= 1;
    },
    getCellName(cellDetails) {
      return cellDetails?.name;
    },
    getCellDesc(cellDetails) {
      return cellDetails?.description;
    },
    getItemDetailsName(itemDetails) {
      return itemDetails?.name;
    },
    getItemDetailsDesc(itemDetails) {
      return itemDetails?.description;
    },
    async onSearchProducts() {
      this.searchLoading = true;
      await this.getItems({
        product_details__name: this.searchString
      });
      this.searchLoading = false;
    },
    async getItems(params = {}) {
      try {
        const newItems = await depotService.getMyProducts({
          ...params,
          uuid_product: this.$route.params.barCode,
          id_state: 810,
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage
        });
        console.log(newItems);
        this.items.push(...newItems);
        // console.log('this.items', this.items);
        // this.items = Object.values(this.items);
        this.items = uniqueByUUID(this.items);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        this.searchLoading = false;
        this.setErrorNotification(e);
      }
    },
    async emitPageChange() {
      try {
        await this.getItems();
      } catch (e) {
        this.setErrorNotification(e);
      }
    }
  },
  async mounted() {
    EventBus.$on("added-product", async () => {
      this.setLoading(true);
      await this.getItems();
    });
    try {
      this.setLoading(true);
      this.mainItem = await depotService.getProduct(this.$route.params.barCode);
      // this.options.children.name = this.mainItem.options_details.name
      // this.options.children.amount = this.mainItem.options_details.count
      await this.getItems();
      this.getCapacity();
      console.log("main", this.mainItem);
    } catch (e) {
      this.setLoading(false);
      this.setErrorNotification(e);
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
.products-list {
  &-header {
    background: #eeeeee;
    text-transform: uppercase;
    font-weight: bold;
    color: #676767;
    font-size: 18px;
    padding: 12px 0;
    border-radius: 12px 12px 0 0;
  }

  &-pagination {
    background: white;
  }

  &-data {
    background: white;

    > div {
      padding: 25px 5px;
      font-size: 15px;
    }
  }

  &-header,
  &-data {
    display: flex;
    margin-bottom: 5px;

    > div:nth-child(1) {
      width: 5%;
    }

    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
      width: 20%;
    }

    > div:nth-child(5) {
      width: 15%;
    }

    > div:nth-child(6) {
      width: 15%;
    }
  }
}
</style>
